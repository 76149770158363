<template>
  <ion-page>
    <ion-content class="backgroundImage">
      <div class="bcg">
        <div class="bcg-window"></div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage } from '@ionic/vue';

export default {
  name: 'no-mobile',
  components: {
    IonPage,
    IonContent,
  },
};
</script>

<style>
.bcg {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  background-image: url('../images/bcg-no-mobile.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.bcg-window {
  width: 533px;
  height: 324px;
  margin-top: 250px;
  background-image: url('../images/bcg-no-mobile-window.png');
  background-repeat: no-repeat;
  background-size: cover;
}

</style>
